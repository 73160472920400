import React, { FunctionComponent } from "react"
import Img from "gatsby-image"
import { Store } from "../models/store"
import { Helmet } from "react-helmet"
import { helmetJsonLdProp } from "react-schemaorg"
import { LocalBusiness } from "schema-dts"
import { StoreLocation } from "../models/storeLocation"
import { parseTime } from "../utils/parseTime"

interface OwnProps {
  store: Store
  storeLocation: StoreLocation
  isMainStore: boolean
}

type Props = OwnProps

const StoreInfo: FunctionComponent<Props> = ({
  store,
  storeLocation,
  isMainStore,
}) => {
  return (
    <>
      {isMainStore ? (
        <Helmet
          script={[
            helmetJsonLdProp<LocalBusiness>({
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              name: `Tote-A-Poke - ${storeLocation}`,
              image: store.image.publicURL,
              telephone: store.phone,
              address: {
                "@type": "PostalAddress",
                streetAddress: `${store.address}, ${store.city} ${store.zipcode}`,
              },
              openingHoursSpecification: {
                "@type": "OpeningHoursSpecification",
                opens: parseTime(store.opening_time),
                closes: parseTime(store.closing_time),
              },
              paymentAccepted: "Cash, Credit Card",
              priceRange: "$",
              email: "tote-a-poke@clnk.com",
              brand: "Tote-A-Poke",
            }),
          ]}
        />
      ) : null}
      <section className={"flex flex-row my-2 md:mx-2 lg:my-2"}>
        <div
          className={`rounded border-2 border-black h-32 w-40 lg:h-40 lg:w-56 bg-gray-100`}
        >
          <Img
            fluid={store.image.childImageSharp.fluid}
            alt={store.title}
            style={{
              objectFit: "fill",
              width: "100%",
              height: "100%",
            }}
            imgStyle={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            className={`min-w-full`}
          />
        </div>

        <div
          className={
            "flex flex-col justify-center ml-3 lg:ml-4 text-sm lg:text-base"
          }
        >
          <h2 className={"font-bold"}>{store.title}</h2>
          <p>
            <time>{store.opening_time}</time> -{" "}
            <time>{store.closing_time}</time>
          </p>
          <p className={"xl:hidden"}>{store.address}</p>
          <p className={"xl:hidden"}>
            {store.city} {store.zipcode}
          </p>
          <p className={"hidden xl:block"}>
            {store.address}, {store.city} {store.zipcode}
          </p>
          <a href={`tel:${store.phone}`}>{store.phone}</a>
        </div>
      </section>
    </>
  )
}

export default StoreInfo
