import React, { FunctionComponent } from "react"
import StoreInfo from "./StoreInfo"
import LayoutWithMap from "./LayoutWithMap"
import { StoreLocation } from "../models/storeLocation"
import { graphql } from "gatsby"
import { LocationQueryResponse } from "../models/locationQueryRespons"
import { Helmet } from "react-helmet"

interface OwnProps {
  storeLocation: StoreLocation
  mapZoomLevel: number
}

type Props = OwnProps & LocationQueryResponse

const StorePage: FunctionComponent<Props> = ({ data }) => {
  const storeLocation = data.markdownRemark.frontmatter
    .location as StoreLocation
  const stores = data.markdownRemark.frontmatter.stores

  return (
    <>
      <Helmet title={`Tote-A-Poke - ${storeLocation}, OK`} />
      <LayoutWithMap
        mapLocation={storeLocation}
        mapZoomLevel={14}
        selectedLocation={storeLocation}
      >
        <div
          className={
            "flex flex-col md:flex-row lg:flex-col self-center justify-around lg:w-1/2 p-2 md:p-4"
          }
        >
          {stores.map((store, index) => (
            <StoreInfo
              key={index}
              store={store}
              storeLocation={storeLocation}
              isMainStore={index === 0}
            />
          ))}
        </div>
      </LayoutWithMap>
    </>
  )
}

export default StorePage

export const query = graphql`
  query LocationQuery($location: String!) {
    markdownRemark(frontmatter: { location: { eq: $location } }) {
      frontmatter {
        location
        stores {
          title
          opening_time
          closing_time
          address
          city
          zipcode
          phone
          has_carwash
          image {
            publicURL
            relativePath
            childImageSharp {
              fixed(fit: COVER, width: 228, height: 164, toFormat: JPG) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fluid(fit: COVER, toFormat: JPG) {
                ...GatsbyImageSharpFluid_withWebp
              }
              resize(width: 228, height: 164, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
    }
  }
`
