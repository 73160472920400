export const parseTime = (s: string) => {
  let [time, period] = s.split(" ")
  if (period === "PM") {
    const [hours, minutes] = time.split(":")
    time = `${+hours + 12}:${minutes}`
  } else if (time.length === 4) {
    time = "0" + time
  }
  const dateTime = "1970-01-01T" + time + ":00-06:00"
  return new Date(dateTime).toISOString()
}
